import React from 'react';

import { FAQSection, BenefitsSection } from 'src/sections';
import { Page } from 'src/components';

/**
 * @todo finish benefits pages
 */
export default function Benefits() {
  return (
    <Page title="Catch Benefits" path="benefits" image="/graphics/benefits.png">
      <BenefitsSection title="We handle all of it so you don’t have to" />
      <FAQSection />
    </Page>
  );
}
